import axios from 'axios';

import { useAccountStore } from '@/stores/account';
import { getBearerToken } from '@/helpers/auth';

let cancel = {};

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_PRODUCT_API_BASE_URL + '/orders/',
});

// Intercept request and refresh Auth header
instance.interceptors.request.use((config) => {
  config.headers.Authorization = getBearerToken();
  if (!config.params) config.params = {};
  if (config.method === 'get') {
    if (!config.params.f) {
      const { filter } = useAccountStore();
      if (filter) config.params.f = encodeURI(JSON.stringify(filter));
    }
  }
  if (!config.params.region) {
    const { region } = useAccountStore();
    config.params.region = region;
  }
  if (!config.params.locale) {
    const { locale } = useAccountStore();
    config.params.locale = locale;
  }
  return config;
});

export { instance, cancel };
