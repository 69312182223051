<template>
  <div class="postalcode-selector">
    <GenericPopup
      :class="['popup-wrapper', visible ? 'visible' : '']"
      :visible="visible"
      :slim="true"
      @close="visible = false"
    >
      <template v-slot:content>
        <span>{{ $t('postalcode.warehouse_logic_info') }}</span>
      </template>
    </GenericPopup>
    <div
      :class="{
        field: true,
        balloon: true,
        visible: showInvalidText,
        'touch-balloon': true,
      }"
      data-balloon-length="fit"
      data-balloon-visible
      :aria-label="$t('postalcode.invalid')"
      data-balloon-pos="down-left"
    >
      <div class="is-inline-block" style="white-space: nowrap">
        <label class="label is-uppercase is-size-7 is-inline-block">{{ $t('postalcode.delivery_to') }}</label>
        <span @click.prevent="visible = true" class="icon popup__open">
          <font-awesome-icon :icon="['far', 'info-circle']" />
        </span>
      </div>
      <div class="is-flex" style="align-items: center; width: min-content">
        <div class="control">
          <input
            :class="{
              input: true,
              'is-danger': validPostalcode === false,
            }"
            type="text"
            v-model="pcode"
            placeholder="Postnummer"
            maxlength="5"
            @paste="onPaste"
            @focus="onFocus"
            @blur="onBlur"
            @keydown.enter="
              // @ts-ignore
              $event.target.blur()
            "
          />
          <font-awesome-icon :class="['postalcode-icon']" :icon="['fas', 'map-marker-alt']" />
        </div>
        <span v-if="user && user.isBendersEmployee" class="postalcode-city">
          <div class="control">
            <div :class="['select', 'is-full-width']">
              <select
                class="is-full-width warehouse-select"
                @change="onWarehouseSelect"
                v-model="selectedWarehouseKey"
                ref="warehouseSelect"
                v-bind:style="{ width: warehouseSelectWidth }"
              >
                <!-- Show the normal city as an unselectable option in the dropdown -->
                <option
                  v-if="sortedWarehouses.map((w) => w.label).includes(city) == false"
                  :value="city"
                  selected
                  hidden
                >
                  {{ city }}
                </option>
                <option
                  v-for="warehouse in sortedWarehouses"
                  :key="warehouse.label"
                  :value="warehouse.label"
                  class="warehouse-option"
                >
                  {{ warehouse.label }}
                </option>
              </select>
            </div>
          </div>
        </span>
        <span v-else class="postalcode-city">{{ city }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { mapState, mapActions } from 'pinia';
  import axios from 'axios';

  import GenericPopup from '@/components/popup/Generic.vue';
  import { usePostalcodeStore } from '@/stores/postalcode';
  import { useCartStore } from '@/stores/cart';
  import { useAccountStore } from '@/stores/account';
  import { getBearerToken } from '@/helpers/auth';

  const baseurl = import.meta.env.VITE_APP_INTEGRATIONS_URL;

  export default {
    name: 'PostalcodeSelector',
    components: {
      GenericPopup,
    },
    data() {
      return {
        visible: false,
        postalcode: '',
        lastValidPostalcode: '',
        selectedWarehouseKey: '',
        warehouseSelectWidth: '110px',
        city: '',
        showInvalidText: false,

        defaultPostalcode: null,
        validPostalcode: false,
      };
    },
    // asyncComputed: {
    //   async validPostalcode() {
    //     return await new Promise((resolve, reject) => {
    //       const postalcode = this.postalcode.replace(/\D/g, '');
    //       if (postalcode.length >= 5 && postalcode.length <= 6) {
    //         axios
    //           .get(`${baseurl}postalcode/${postalcode}`, {
    //             headers: {
    //               'Content-Type': 'application/json',
    //               Authorization: `Bearer ${Cookie.get('benders_logged_in')}`,
    //             },
    //           })
    //           .then((resp) => {
    //             this.showInvalidText = !resp.data?.valid;
    //             this.city = resp.data?.city;
    //             resolve(resp.data?.valid);
    //           })
    //           .catch((error) => {
    //             reject(error);
    //           });
    //       } else {
    //         resolve(false);
    //       }
    //     });
    //   },
    //   async defaultPostalcode() {
    //     return await new Promise((resolve, reject) => {
    //       const postalcode = this.info.defaultPostalcode.replace(/\D/g, '');
    //       if (postalcode.length >= 5 && postalcode.length <= 6) {
    //         axios
    //           .get(`${baseurl}postalcode/${postalcode}`, {
    //             headers: {
    //               'Content-Type': 'application/json',
    //               Authorization: `Bearer ${Cookie.get('benders_logged_in')}`,
    //             },
    //           })
    //           .then((resp) => {
    //             resolve(resp.data?.valid ? resp.data.postalcode : null);
    //           })
    //           .catch((error) => {
    //             reject(error);
    //           });
    //       } else {
    //         resolve(null);
    //       }
    //     });
    //   },
    // },
    computed: {
      ...mapState(usePostalcodeStore, {
        statePostalcode: 'postalcode',
      }),
      ...mapState(useCartStore, ['info']),
      ...mapState(useAccountStore, ['user']),
      pcode: {
        get() {
          return this.postalcode;
        },
        set(value) {
          this.postalcode = value.replace(/\D/g, '');
          // .replace(/^(\d{3})(\d{1,3})/, '$1 $2') // BEN-1474: Remove space from postalcode
        },
      },
      selectedWarehouse: {
        get() {
          return this.sortedWarehouses?.find?.((w) => w.label == this.selectedWarehouseKey);
        },
        set(value) {
          if (typeof value == 'string') this.selectedWarehouseKey = value;
          else if (value?.label) this.selectedWarehouseKey = value.label;
        },
      },
      sortedWarehouses() {
        let warehouses = [...this.info.warehouses].filter((item, pos) => {
          return (
            this.info.warehouses.findIndex((w) => w.label == item.label) == pos &&
            item?.zipCode &&
            item?.categories?.length > 0
          );
        });
        return warehouses.sort((a, b) => (a.label > b.label ? 1 : -1));
      },
    },
    methods: {
      ...mapActions(usePostalcodeStore, ['setPostalcode']),
      onFocus(e) {
        e.target.setSelectionRange(0, this.postalcode.length);
      },
      onBlur() {
        if (this.postalcode.length > 0) {
          this.postalcode = this.lastValidPostalcode;
        } else {
          let postalcode = this.defaultPostalcode || '74640';
          if (typeof postalcode === 'string' && parseInt(postalcode.replace(/\D/g, '')) > 0) {
            this.postalcode = postalcode;
            this.lastValidPostalcode = this.postalcode;
            this.setPostalcode(this.postalcode);
          }
        }
      },
      onPaste(e) {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain').replace(/\D/g, '');
        // .replace(/^(\d{3})(\d{1,3})/, '$1 $2') // BEN-1474: Remove space from postalcode
        if (this.pcode === text) return;
        this.validPostalcode = false;
        this.$nextTick(() => {
          this.pcode = text;
        });
      },
      onWarehouseSelect(e) {
        if (this.selectedWarehouse?.zipCode && this.selectedWarehouse.zipCode != this.pcode) {
          this.validPostalcode = false;
          this.$nextTick(() => {
            this.pcode = this.selectedWarehouse?.zipCode ?? '';
            this.city = this.selectedWarehouse?.label ?? '';

            let optionsEl = e.target?.options?.[e.target?.selectedIndex];
            if (optionsEl?.offsetWidth) this.warehouseSelectWidth = optionsEl.offsetWidth + 40 + 'px';
          });
        }
      },
      assignPostalcode() {
        if (this.validPostalcode) {
          this.lastValidPostalcode = this.postalcode;
          this.setPostalcode(this.postalcode);
        }
      },
      checkValidPostalcode(postalcode) {
        return new Promise((resolve, reject) => {
          // if postalcode is not a string or empty, return false
          if (typeof postalcode !== 'string' || postalcode.length < 1) {
            resolve(false);
            return;
          }
          postalcode = postalcode.replace(/\D/g, '');
          if (postalcode.length >= 5 && postalcode.length <= 6) {
            axios
              .get(`${baseurl}postalcode/${postalcode}`, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: getBearerToken(),
                },
              })
              .then((resp) => {
                resp.data.postalCode = postalcode;
                resolve(resp.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(false);
          }
        });
      },
    },
    watch: {
      'info.defaultPostalcode': {
        // Check if the default postalcode is valid
        handler() {
          this.checkValidPostalcode(this.info.defaultPostalcode).then((r: any) => {
            if (!r.valid) {
              this.defaultPostalcode = null;
              return;
            }

            this.defaultPostalcode = r.postalCode;
          });
        },
        immediate: true,
      },
      postalcode: {
        handler() {
          this.checkValidPostalcode(this.postalcode).then((r: any) => {
            this.showInvalidText = !r.valid;
            this.city = r.city;
            this.validPostalcode = r.valid;
          });
        },
      },
      city: {
        handler() {
          if (this.$refs?.warehouseSelect && this.city) {
            this.$nextTick(() => {
              // @ts-ignore
              this.selectedWarehouse = this.city;
              // @ts-ignore
              if (this.$refs.warehouseSelect.selectedIndex <= 0) this.warehouseSelectWidth = '110px';
            });
          }
        },
      },
      statePostalcode: {
        handler(newVal) {
          if (this.postalcode == '') this.postalcode = newVal;
          if (this.lastValidPostalcode == '') this.lastValidPostalcode = newVal;
        },
        immediate: true,
      },
      defaultPostalcode: {
        handler() {
          // let postalcode = info.defaultPostalcode
          let postalcode = this.defaultPostalcode;
          if (!postalcode) return;

          if (this.postalcode == '' && this.lastValidPostalcode == '') this.setPostalcode(postalcode);
          if (this.postalcode == '') this.postalcode = postalcode;
          if (this.lastValidPostalcode == '') this.lastValidPostalcode = postalcode;
        },
        immediate: true,
      },
      validPostalcode: {
        handler() {
          this.assignPostalcode();
        },
      },
    },
  };
</script>

<style scoped>
  .postalcode-icon {
    top: 0.6em;
    position: absolute;
    right: 0.65em;
    pointer-events: none;
  }

  .postalcode-selector input {
    width: 6.5em;
    padding: 0.5em;
    padding-right: 1.5em;
    text-align: center;
  }

  [aria-label][data-balloon-pos] {
    cursor: default;
  }

  .postalcode-city {
    margin-left: 0.4em;
    min-width: 3.5em;
  }

  .field.balloon {
    --balloon-color: #a6192e;
  }

  .field.balloon:before {
    margin-left: 45px;
  }

  .field.balloon:not(.visible):before {
    display: none;
  }

  .field.balloon:not(.visible):after {
    display: none;
  }

  .popup-wrapper.visible {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    top: 40vh;
  }

  .warehouse-select {
    border: none;
    padding-left: 0;
  }
  .warehouse-select > * {
    width: min-content;
  }
</style>
