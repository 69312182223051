<template>
  <div :class="['productlist', 'is-flex', 'table-container']" style="flex-direction: column">
    <!-- V-if for this might not be a good idea... -->
    <template v-if="rows">
      <table class="table is-fullwidth">
        <div class="is-hidden-tablet">
          <slot name="mobile_header" :rows="rows" />
        </div>
        <thead>
          <tr>
            <th
              v-for="(column, index) in c_columns"
              :key="'th' + column.name + index"
              :class="['is-uppercase', 'is-size-7', `col-${column.name}`]"
            >
              <slot :name="'header_' + column.name" :column="column">
                {{ column.label }}
              </slot>
            </th>
          </tr>
        </thead>
        <transition-group name="list-complete" tag="tbody">
          <tr
            v-for="(row, index) in rows"
            :key="'tr' + row.id"
            :class="['list-complete-item', row._animation ? `animation-${row._animation}` : null, row.class]"
          >
            <td
              v-for="column in c_columns"
              :key="'td' + column.name + index"
              :class="[`col-${column.name}`, { 'is-flex': column.direction }]"
              :style="{
                gridColumn: column.span ? `span ${column.span}` : undefined,
                flexDirection: column.direction ? (column.direction as any) : undefined,
              }"
            >
              <component :is="wrapperClass(row[column.name]) ? 'div' : 'span'" :class="wrapperClass(row[column.name])">
                <slot v-if="isMobile && showLabel(row[column.name])" :name="'header_' + column.name" :column="column">
                  <label v-if="column.label" class="label is-uppercase is-size-7 margin-none-bottom is-hidden-tablet">
                    {{ column.label }}
                  </label>
                </slot>
                <slot :name="'column_' + column.name" :row="row" :column="column">
                  <component
                    v-for="(r, k) in components(row[column.name], column)"
                    :key="row.id + '_' + (k || r.name || r.resolved)"
                    :is="r.resolved"
                    :data="r"
                    @event="cellEvent($event, row)"
                    :class="[column.class, r.class]"
                    :style="combineStyles(column.style, r.style)"
                  />
                </slot>
              </component>
            </td>
          </tr>
        </transition-group>
      </table>
    </template>
  </div>
</template>

<script lang="ts">
  import type { ProductListColumn, ProductlistRow } from '@/types/Productlist';

  import { defineAsyncComponent } from 'vue';
  import isMobile from '@/mixins/is-mobile';

  export default {
    name: 'ProductList',
    props: {
      columns: {
        type: Array as () => ProductListColumn[],
        required: true,
        // Example
        // [
        //     {
        //         component: 'name',
        //         name: 'name',
        //         label: 'Name',
        //     }
        // ]
      },
      rows: {
        type: Array as () => ProductlistRow[],
        required: true,
        // Array of objects with the same structure as columns
      },
    },
    data() {
      return {};
    },
    setup() {
      return {
        ...isMobile(),
      };
    },
    components: {
      product_image: defineAsyncComponent(() => import('./components/Image.vue')),
      product_name: defineAsyncComponent(() => import('./components/Name.vue')),
      product_warehouse_status: defineAsyncComponent(() => import('./components/WarehouseStatus.vue')),
      product_checkbox: defineAsyncComponent(() => import('./components/Checkbox.vue')),
      product_input: defineAsyncComponent(() => import('./components/Input.vue')),
      product_select: defineAsyncComponent(() => import('./components/Select.vue')),
      product_money: defineAsyncComponent(() => import('./components/Money.vue')),
      product_quantity: defineAsyncComponent(() => import('./components/Quantity.vue')),
      product_string: defineAsyncComponent(() => import('./components/String.vue')),
      product_measurement: defineAsyncComponent(() => import('./components/Measurement.vue')),
    },
    computed: {
      c_columns() {
        return this.columns.filter((c) => c.hidden !== true);
      },
    },
    methods: {
      components(row?: any, column?: any) {
        let rows = [] as any;
        if (!row) return rows;

        if (!Array.isArray(row)) {
          rows.push(row);
        } else {
          rows = row;
        }

        return rows.map((r) => {
          r.resolved = this.resolveComponent(r, column);
          return r;
        });
      },
      resolveComponent(row?: any, column?: any) {
        const name = row?.component || column?.component;
        const componentName = `product_${name}`;
        if (this.$options.components?.[componentName]) {
          return componentName;
        }

        return 'product_string';
      },
      cellEvent(event, row) {
        this.$emit('cell-event', { ...event, row: row.id });
      },
      combineStyles(...styles) {
        return styles.reduce((acc, style) => {
          if (!style) return acc;
          if (typeof style === 'string') {
            return acc + style + ';';
          }
          return { ...acc, ...style };
        }, '');
      },
      showLabel(row) {
        return this.components(row).some((r) => r.value || r.values);
      },
      wrapperClass(row) {
        return this.components(row).find((r) => r.wrapper)?.wrapper;
      },
    },
  };
</script>

<style scoped lang="sass">
  @import 'bulma/sass/utilities/mixins.sass'

  .productlist:not(.no-bottom-border)
    tbody tr:last-child td
      border-bottom-width: thin

  .cart-dropdown .table-container::-webkit-scrollbar
    width: 8px
    height: 8px
  .cart-dropdown .table-container::-webkit-scrollbar-button
    width: 0px
    height: 0px
  .cart-dropdown .table-container::-webkit-scrollbar-thumb
    background: rgba(0, 0, 0, 0.4)
    border: 0px none transparent
    border-radius: 50px
  .cart-dropdown .table-container::-webkit-scrollbar-thumb:hover
    background: rgba(0, 0, 0, 0.6)
  .cart-dropdown .table-container::-webkit-scrollbar-thumb:active
    background: rgba(0, 0, 0, 0.6)
  .cart-dropdown .table-container::-webkit-scrollbar-track
    background: transparent
    border: 0px none transparent
    border-radius: 50px
  .cart-dropdown .table-container::-webkit-scrollbar-track:hover
    background: transparent
  .cart-dropdown .table-container::-webkit-scrollbar-track:active
    background: transparent
  .cart-dropdown .table-container::-webkit-scrollbar-corner
    background: transparent

  @include mobile
    .no-mobile-view
      .is-hidden-tablet
        display: none
    .productlist:not(.no-mobile-view)
      thead
        display: none

      tbody tr
        display: grid
        grid-template-columns: repeat(5, 1fr)
        border-top: 1px solid #e2e3e4
        padding: 0.5rem 0

      td, th
        border: none !important
        padding: 0 0.75rem !important

  .productlist.table-container
    overflow: visible

  .list-complete-enter-active:not(.animation-loading),
  .list-complete-leave-active:not(.animation-loading)
    transition: all 0.5s

  .list-complete-enter-from:not(.animation-loading),
  .list-complete-leave-to:not(.animation-loading)
    opacity: 0
    transform: translateY(-30px)

  .list-complete-leave-active:not(.animation-loading)
    pointer-events: none
    position: absolute

  .productlist tbody tr.animation-flash
    animation: flash 0.5s 2 ease-in-out

  @keyframes flash
    0%
      opacity: 1
      // box-shadow: inset 0px 0px 6px 1px transparent
    50%
      opacity: 0.2
      // box-shadow: inset 0px 0px 6px 1px #a6192e
    100%
      opacity: 1
      // box-shadow: inset 0px 0px 6px 1px transparent

  // :deep(.productlist) table
  //   .orderrow-extras
  //     display: none
  //   .orderrow-extras.orderrow-extras-open
  //     display: table-row
  //   .mobile-orderrow-extras.orderrow-extras.orderrow-extras-open
  //     border-top: 1px solid #e2e3e4
  //     display: block
  //     padding-top: 10px
  //   .orderrow-extras-open, .orderrow-extras-open > td
  //     border-bottom: none
  //   .orderrow-extras-open-parent, .orderrow-extras-open-parent > td
  //     border-top: none

  //   .list-complete-item
  //     transition: all 1s
  //   .list-complete-enter, .list-complete-leave-to
  //     opacity: 0
  //     transform: translateY(-30px)
  //   .list-complete-leave-active
  //     position: absolute
  //   .disabled
  //     color: grey
  //   .delivery-row:hove
  //     cursor: pointer
  //     background: rgb(243, 243, 243)
  //   .cart-unit > input
  //     appearance: none
  //     -moz-appearance: none
  //     -webkit-appearance: none
  //     background: inherit
  //     border: none
  //     outline: none
  //     padding: 0
  //     padding-left: 0.25rem
  //     padding-right: 0.25rem
  //     text-align: center
  //     height: inherit
  //   .cart-unit > input:focus
  //     appearance: none
  //     -moz-appearance: none
  //     -webkit-appearance: none
  //     background: transparent
  //     outline: none
  //     border: none
  //     border-bottom: 0.5px solid black
</style>
