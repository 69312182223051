import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';
import i18n from '@/languages/i18n';
import _ from 'lodash';
import router from '@/router';

import { useAccountStore } from '@/stores/account';
import { useNotificationStore } from '@/stores/notification';
import { useDebugStore } from '@/stores/debug';
import { getBearerToken } from '@/helpers/auth';

// const cache = setupCache({
//       readHeaders: true,
//       exclude: { query: false },
//       invalidate: async (config: { store?: Storage; uuid: string }, request) => {
//         if (instance.defaults['Authorization'] !== request.headers?.Authorization || (request.params && request.params.nocache === true)) {
//           await config.store?.removeItem(config.uuid);
//         }
//         instance.defaults['Authorization'] = request.headers?.Authorization;
//       },
//     });

let cancel = {};

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_WP_API_BASE_URL + 'benders/v1',
  // adapter: cache ? cache.adapter : undefined,
});

const triggerKundportalenConnectionError = _.debounce(() => {
  if (
    window.sessionStorage.getItem('kp_error_status') === 'pending' &&
    +(window.sessionStorage.getItem('kp_error_count') ?? 0) >= 4
  ) {
    window.sessionStorage.setItem('kp_error_status', 'active');
    const store = useNotificationStore();
    store.clearNotification();
    store.addNotification({
      message: i18n.global.t('errors.portal.connection_problems'),
      source: `kundportalen-error`,
      timeout: 0,
    });
  }
}, 5 * 1000);

// Intercept request and refresh Auth header
instance.interceptors.request.use((config) => {
  config.headers.Authorization = getBearerToken();
  if (!config.params) config.params = {};
  if (config.method === 'get') {
    if (!config.params.f) {
      const { filter } = useAccountStore();
      if (filter) config.params.f = encodeURI(JSON.stringify(filter));
    }
  }
  if (!config.params.region) {
    const { region } = useAccountStore();
    config.params.region = region;
  }
  if (!config.params.locale) {
    const { locale } = useAccountStore();
    config.params.locale = locale;
  }
  const { debugMode } = useDebugStore();
  if (debugMode) {
    config.params.debug = true;
  }

  return config;
});

// Intercept response and check for a notification
instance.interceptors.response.use(
  (response) => {
    const store = useNotificationStore();
    const errorStatus = window.sessionStorage.getItem('kp_error_status');
    if (errorStatus === 'pending' || errorStatus === 'active') {
      window.sessionStorage.removeItem('kp_error_status');
      window.sessionStorage.removeItem('kp_error_count');
    }
    if (errorStatus === 'active') {
      store.clearNotification();
      store.addNotification({
        message: i18n.global.t('errors.portal.connection_restored'),
        source: `kundportalen-error`,
      });
    }

    if (response.data.data && response.data.data.notification) {
      if (typeof response.data.data.notification === 'object') {
        // Order HLS Notifications are in the same level...
        return response;
      }
      store.clearNotification();
      const notifications = response.data.data.notification.split(/\n|\\n/);
      for (let notification of notifications) {
        store.addNotification({ message: notification, source: `kundportalen-${response.config.url}`, timeout: 8 });
      }
    }

    if (response.data.error && response.data.code === 403) router.replace('/403');
    if (response.data.error && response.data.code === 404) router.replace('/404');

    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      router.replace('/login');
    }

    const ignoredStatus = [401, 403, 404];
    if (!error.__CANCEL__ && (!error.response || !ignoredStatus.includes(error.response.status))) {
      window.sessionStorage.setItem('kp_error_status', 'pending');
      window.sessionStorage.setItem('kp_error_count', '' + (window.sessionStorage.getItem('kp_error_count') ?? 0) + 1);
      triggerKundportalenConnectionError();
    }
    return Promise.reject(error);
  }
);

export { instance, cancel };
