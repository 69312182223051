import type { LocationQueryValue } from 'vue-router';

import { defineStore } from 'pinia';
import axios from 'axios';
import {
  resetPassword,
  defaultRepresentativeFilter,
  getDefaultUserRegion,
  refreshCartInfo,
} from '@/data/api/kundportal/requests';
import i18n from '@/languages/i18n';
import Cookie from 'js-cookie';
import { supportedLocales } from '@/languages/i18n';
import { languageMap, languageMapServiceLayer } from '@/helpers';
import { getRegionAndDivision } from '@/helpers/index';

import { useNotificationStore } from './notification';
import { useSessionStore } from './session';
import { usePostalcodeStore } from './postalcode';
import { useHistoryStore } from './history';

import { useStorage } from '@vueuse/core';
import { nextTick } from 'vue';
import { getBearerToken } from '@/helpers/auth';

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v),
};

const userJsonSerializer = {
  read: (v: any) => {
    if (v) {
      let user = JSON.parse(v);
      // If the user is not logged in, we don't want to return the user object
      if (!getBearerToken()) {
        return null;
      }
      return user;
    }
  },
  write: (v: any) => JSON.stringify(v),
};

const corporationFilterMap = new Map([
  ['sweden', 'EDS|Benders Sverige AB'],
  ['norway', 'SAN|Benders Norge AS'],
  ['germany', 'KRI|Benders Deutschland GmbH'],
  ['finland', 'FIN|Benders Finland OY'],
  ['EDS', 'EDS|Benders Sverige AB'],
  ['SAN', 'SAN|Benders Norge AS'],
  ['KRI', 'KRI|Benders Deutschland GmbH'],
  ['FIN', 'FIN|Benders Finland OY'],
]);

var userUpdated;
if ('BroadcastChannel' in self) {
  userUpdated = new BroadcastChannel('benders_user_updated');
  userUpdated.onmessage = (e) => {
    sessionStorage.setItem('user', e.data);
    window.onfocus = () => {
      window.onfocus = null;
      location.reload();
    };
  };
}

const notifyUserUpdate = (data: User = {}) => {
  const json = JSON.stringify(data);
  // sessionStorage.setItem('user', json);
  if (userUpdated) userUpdated.postMessage(json);
};

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_AUTH_API_BASE_URL,
});

instance.defaults.headers.common['Authorization'] = getBearerToken();

// Intercept response and check for a notification
instance.interceptors.response.use(
  (response) => {
    if (response?.data?.notification) {
      const { clearNotification, addNotification } = useNotificationStore();
      clearNotification();
      addNotification({ message: response.data.notification, source: `authAPI-${response.config.url}`, timeout: 0 });
    }

    return response;
  },
  (error) => {
    const response = error.response;
    if (response?.data?.notification) {
      const { clearNotification, addNotification } = useNotificationStore();
      clearNotification();
      addNotification({ message: response.data.notification, source: `authAPI-${response.config.url}`, timeout: 0 });
    }

    const ignoredStatus = [401, 403, 404];
    if (!error.__CANCEL__ && (!error.response || !ignoredStatus.includes(error.response.status))) {
      console.error(error);
    }

    return Promise.reject(error);
  }
);

const parseJwt = (token: string) => {
  try {
    if (!token) return null;
    return JSON.parse(window.atob(token.split('.')[1]).toString());
  } catch (e) {
    console.error(e);
    return null;
  }
};

interface User {
  username?: string;
  user?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  exp?: number;
  contacts?: any[]; // TODO: Add type
  customers?: any[]; // TODO: Add type
  isBendersEmployee?: boolean;
  country?: string;
  region?: string;
  actingAsUser?: boolean;
  allowedInProducts?: boolean;
  allowedInCQuotes?: boolean;
  accessibleAssortments?: string[];
  purchasableAssortments?: string[];
}

interface Filter {
  corporation: string;
  representative: string;
  customer: string;
}

interface Status {
  loggedIn: boolean;
  PRODUCT_API_ENABLE: boolean;
  acting_as_user: boolean;
}

enum StoreType {
  user = 'user',
  filter = 'filter',
  region = 'region',
}

const language = localStorage.getItem('language') || 'sv-SE';
i18n.global.locale.value = language as any;

export const useAccountStore = defineStore('account', {
  state: () => {
    return {
      stores: {
        notification: useNotificationStore(),
        session: useSessionStore(),
        postalcode: usePostalcodeStore(),
        history: useHistoryStore(),
      },
      user: useStorage<User | null>('user', null, localStorage, {
        serializer: userJsonSerializer,
      }),
      filter: useStorage<Filter | null>('filter', { corporation: '', representative: '', customer: '' }, localStorage, {
        serializer: jsonSerializer,
      }),
      _region: useStorage<string | null>('region', null, localStorage),
      token: '' as string,
      loggingIn: false,
    };
  },
  actions: {
    resetState(type?: StoreType) {
      switch (type) {
        case 'user':
          this.user = null;
          this.token = '';
          break;
        case 'filter':
          this.filter = { corporation: '', representative: '', customer: '' };
          break;
        case 'region':
          this._region = null;
          break;
        default:
          this.user = null;
          this.token = '';
          this.filter = { corporation: '', representative: '', customer: '' };
          this._region = null;
          break;
      }
    },
    updateFilter(filter: Filter) {
      if (filter === null) {
        this.resetState(StoreType.filter);
        return;
      }
      this.filter = filter;

      // Update the region
      if (filter?.corporation) {
        let region = filter.corporation.split('|')[0];
        this.updateRegion(region);
      }

      if (this.status.loggedIn) {
        refreshCartInfo().then((r) => {
          if (!r) return;
          this.stores.history.reload(); // Force reload the app
        });
      }
    },
    updateRegion(region: string) {
      const regionChanged = this._region !== region;
      if (region === null) {
        this._region = null;
        return;
      }

      // For benders employees, make sure the corporation filter matches the region
      if (this.user?.isBendersEmployee && this.filter?.corporation) {
        // if it doesn't match, set region to the one from filter
        if (!this.filter.corporation.includes(region)) {
          this._region = this.filter.corporation.split('|')[0];
        }
      }

      this._region = region;
      this.refreshAccessibleAssortments(region);
      if (regionChanged) {
        this.stores.session.clearSession();
      }
      this.stores.postalcode.setWarehouses(region, []);
    },
    refreshAccessibleAssortments(region) {
      return new Promise((resolve, reject) => {
        instance
          .get(`accessibleAssortments/${region || this._region}`)
          .then((r) => {
            if (this.user) {
              this.user.accessibleAssortments = r.data;
              notifyUserUpdate(this.user);
            }
            resolve(true);
          })
          .catch((e) => {
            console.error(e);
            reject();
          });
      });
    },
    async setDefaultUserRegion() {
      if (this._region) return;
      let region = this.user?.region ?? (await getDefaultUserRegion().then((r: any) => r.data?.region));
      this.updateRegion(region);
    },
    loginSuccess(user: User, token: string, region?: string) {
      this.user = user;
      this.token = token;
      notifyUserUpdate(this.user);
      if (region) {
        this.updateRegion(region);
      } else {
        this.setDefaultUserRegion();
      }
    },
    loginHandler(
      resolve,
      reject,
      actingAsUser?: boolean,
      returnurl?: string | LocationQueryValue | LocationQueryValue[]
    ) {
      return (r) => {
        this.resetState();
        this.stores.session.disable('checkout');
        this.stores.notification.clearNotification(true);

        let jwt = parseJwt(r.data.token);
        if (!jwt) {
          console.error('Could not parse JWT token');
          reject('Could not parse JWT token');
          return;
        }
        Cookie.set('benders_logged_in', r.data.token, {
          expires: jwt ? new Date(jwt.exp * 1000) : 2,
        });
        instance.defaults.headers.common['Authorization'] = `Bearer ${r.data.token}`;

        nextTick(() => {
          instance
            .get('session')
            .then(async (r2) => {
              if (r2.data?.isBendersEmployee) {
                const representative = await defaultRepresentativeFilter();
                this.updateFilter({
                  corporation: corporationFilterMap.get(r2.data.region) ?? corporationFilterMap.get('EDS') ?? '',
                  representative,
                  customer: '',
                });
              }

              this.user = r2.data;
              this.loginSuccess(r2.data, r.data.token);
              if (actingAsUser) {
                location.reload();
              } else {
                // We don't want to use router.push here, because the router finishes and alters state,
                // before our lazy loaded components have had time to load. Which can cause some visual inconsistencies
                location.href = returnurl ? returnurl.toString() : '/';
              }
              resolve(true);
            })
            .catch((e) => {
              if (e?.response?.data?.errorCode) {
                console.error('Could not get session', e.response.data.errorCode);
                reject(e.response.data.errorCode);
              } else {
                console.error('Could not get session', e);
                reject(0);
              }
            });
        });
      };
    },
    login({
      username,
      password,
      remember,
      token,
      returnurl,
    }: {
      username: string;
      password: string;
      remember: boolean;
      token: string | undefined | null;
      returnurl?: string | LocationQueryValue | LocationQueryValue[];
    }) {
      this.loggingIn = true;
      this.resetState(StoreType.filter);
      this.stores.postalcode.setPostalcode('');

      this.user = { username };
      return new Promise((resolve, reject) => {
        instance
          .post('login', { email: username, password, remember, token })
          .then(this.loginHandler(resolve, reject, false, returnurl))
          .catch((e) => {
            this.resetState(StoreType.user);
            notifyUserUpdate();
            reject(e);
          });
      });
    },
    logout() {
      this.resetState();

      if (Cookie.get('benders_logged_in')) {
        instance.get('logout').then((r) => {
          if (r.status === 200) {
            window.location.href = '/login';
          }
        });
      }

      Cookie.remove('benders_logged_in');
      this.stores.session.disable('checkout');
      this.stores.postalcode.setPostalcode('');
    },
    actAs(userId: string) {
      return new Promise((resolve, reject) => {
        instance.get(`/act/user/${userId}`).then(this.loginHandler(resolve, reject, true));
      });
    },
    returnToAdmin() {
      return new Promise((resolve, reject) => {
        instance.get(`/act/restore`).then(this.loginHandler(resolve, reject, true));
      });
    },
    userCan(action: string = '') {
      return new Promise<boolean>((resolve) => {
        if (!this.user?.user) {
          resolve(false);
          return;
        }

        instance
          .get(`can/${action}`)
          .then((r) => {
            resolve(!!r.data?.success);
          })
          .catch((e) => {
            if (e.response?.status === 401) {
              resolve(false);
            } else {
              console.error(e);
              resolve(false);
            }
          });
      });
    },
    resetPassword(data: { email?: string; password?: string; nonce?: string }) {
      return new Promise((resolve, reject) => {
        resetPassword(data)
          .then((r: any) => {
            if (r.success) {
              resolve(true);
            } else {
              reject(r.error);
            }
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    validateResetPassword(nonce: string) {
      return new Promise((resolve, reject) => {
        instance
          .get(`validatenonce/${nonce}`)
          .then((r) => {
            if (r.data.success) {
              resolve(true);
            } else {
              reject(i18n.global.t('lost_password.invalidNonce'));
            }
          })
          .catch((e) => {
            console.error(e);
            reject(i18n.global.t('errors.generic_error'));
          });
      });
    },
    setLanguage(language: string) {
      if (language && supportedLocales.includes(language)) {
        i18n.global.locale.value = language as any;
        localStorage.setItem('language', language);
      }
    },
  },
  getters: {
    status(state): Status {
      return {
        loggedIn: !!state.user?.user && !state.loggingIn,
        PRODUCT_API_ENABLE: state.user?.allowedInProducts ?? false,
        // PRODUCT_API_ENABLE: false,
        acting_as_user: false,
      };
    },
    userId(state) {
      return state.user && state.user.user ? state.user.user.id : null;
    },
    isBendersEmployee(state) {
      return state.user ? state.user.isBendersEmployee : false;
    },
    isWeberUser(state) {
      return state.user ? state.user.customers?.some((c) => c.customerNumber === '113515') : false;
    },
    userCustomers(state) {
      return state.user?.customers ?? [];
    },
    allowedPurchase(state) {
      if (!state.user?.purchasableAssortments?.length) return {};
      return state.user.purchasableAssortments.reduce((obj, assortment) => {
        obj[assortment.toLowerCase()] = true;
        return obj;
      }, {});
    },
    // division(state) {
    //   const [, division] = getRegionAndDivision(state.region)
    //   return division
    // },
    region(state) {
      // Map the language to a region if not logged in
      let region = state._region;
      if (!region && !state.user) {
        region = languageMapServiceLayer?.[this.locale] ?? 'SE';
      }

      const [, division] = getRegionAndDivision(region);
      return division; // Yes, this is wrongly named. But changing it will cause a lot of friction for users
    },
    locale() {
      return i18n.global.locale.value || 'sv-SE';
    },
    localeC4() {
      return languageMap?.[(this.locale as unknown as string).replace('_', '-')] || 'sv-SE';
    },
  },
});
