import Cookie from 'js-cookie';

export function getBearerToken(): string | undefined {
  let token;
  if (Cookie.get('benders_logged_in')) {
    token = `Bearer ${Cookie.get('benders_logged_in')}`;
  }

  return token;
}
