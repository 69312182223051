import i18n from '@/languages/i18n';
import _ from 'lodash';

import { useCartStore } from '@/stores/cart';

export * from './session';
export * from './roles';

export function cartUpdateWatcher() {
  const store = useCartStore();
  window.addEventListener('storage', function (e) {
    if (e.key === 'benders-vuex' && !_.isEmpty(e.newValue)) {
      if (e.newValue == e.oldValue) return;

      if (!e.newValue) {
        return;
      }
      const parsed = JSON.parse(e.newValue);
      const cart = parsed?.cart?.cart;

      if (_.isEmpty(cart)) return;

      store.setCart(cart);
    }
  });
}

// Checks if the given array has any duplicates
// @param {Array} arr
// @param {String|Array} key|keys
// @return {Boolean}
export function hasDuplicates(arr, key) {
  const keys = _.isArray(key) ? key : [key];

  // No key, compare set size to array size to determine if there are duplicates
  if (!keys.length) return new Set(arr).size !== arr.length;

  // Check key combination for duplicates
  const seen = new Set();
  return arr.some((item) => {
    const values = keys.map((k) => item[k]);
    const value = values.join('|');
    return seen.has(value) ? true : seen.add(value) && false; // make sure the return value is false
  });
}

export const invalidStatus = Object.freeze({
  customer: 'invalidCustomer',
  pricelist: 'invalidPricelist',
  amountExceeded: 'amountExceededExpiredProducts',
  showInformationPopup: 'showInformationPopup',
  leadtimeError: 'leadtimeError',
  earlyDeliveryDate: 'earlyDeliveryDate',
  blockedBy1299: 'blockedBy1299',
  stockTimeout: 'stockTimeout',
  addressField: 'addressFieldInvalid',
  postalcode: 'postalCodeInvalid',
  generalField: 'generalFieldInvalid',
  stock: 'invalidStock',
  weight: 'invalidWeight',
  userNotAllowedToOrder: 'userNotAllowedToOrder',
  notificationValue: 'invalidNotificationValue',
  noDeliveryMode: 'noDeliveryMode',
  rejectedImportantNotice: 'rejectedImportantNotice',
  noDeliveryTermsType: 'noDeliveryTermsType',
});

export type RegionTypeString = 'SE' | 'FI' | 'DE' | 'NO';
export type DivisionTypeString = 'EDS' | 'FIN' | 'KRI' | 'SAN';

// Returns the region and division for the given value
// @param {String} value
// @return {Array} [region, division]
export function getRegionAndDivision(value): [RegionTypeString, DivisionTypeString] {
  if (!value) return ['SE', 'EDS'];
  value = value.toUpperCase();

  const maps = {
    region: {
      EDS: 'SE',
      FIN: 'FI',
      KRI: 'DE',
      SAN: 'NO',
    },
    division: {
      SE: 'EDS',
      FI: 'FIN',
      DE: 'KRI',
      NO: 'SAN',
    },
  };

  if (maps.region[value]) return [maps.region[value], value];
  if (maps.division[value]) return [value, maps.division[value]];
  return ['SE', 'EDS']; // Default to Sweden
}

export const languageMap = {
  'sv-SE': 'SWEDISH',
  'en-US': 'ENGLISH',
  'fi-FI': 'FINNISH',
  'nb-NO': 'NORWEGIAN',
  'de-DE': 'GERMAN',
};

export function getArticleTitle(article) {
  const language = languageMap[i18n.global.locale.value] || 'SWEDISH';
  return article?.description?.[language] || article?.descriptionExt || article?.description;
}

export const languageMapServiceLayer = {
  'sv-SE': 'SE',
  'en-US': 'GB',
  'fi-FI': 'FI',
  'nb-NO': 'NO',
  'de-DE': 'DE',
};

export function getDeliveryTitle(delivery) {
  const language = languageMapServiceLayer[i18n.global.locale.value] || 'SE';
  return (
    delivery?.itemDescriptionLanguages?.find((item) => item.language === language)?.description ?? delivery?.description
  );
}

export function capitalizeFirstLetter(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.substring(1);
}

export function queryToValue(value: any | any[]): string {
  return (typeof value === 'string' ? value : value?.[0]) ?? '';
}

export function filterFieldHelper(
  fields: {
    bucket?: any;
    options?: any[];
    categories?: string[];
    bucketFilter?: (options: any[]) => any[];
  }[],
  aggs: Record<string, any>,
  category?: string,
  fn?: (field: any, bucket: any) => any | false
): any[] {
  const getBucket = (bucketName: string) => {
    if (!bucketName) return null;
    let path = bucketName.split(':');
    if (!aggs || !aggs[path[0]]) return null;
    let bucket = aggs[path[0]]?.values ?? aggs[path[0]];
    for (let i = 1; i < path.length; i++) {
      bucket = bucket[path[i]];
    }
    return bucket;
  };

  let newFields = [] as any[];
  for (let i = 0; i < fields.length; i++) {
    let field = { ...fields[i] };
    if (category && field.categories && field.categories.indexOf(category) === -1) continue;

    let bucket = getBucket(field.bucket);
    if (bucket) {
      // Convert 'bucket.buckets' to array, if it isn't already
      if (!Array.isArray(bucket.buckets)) {
        let arr = [] as any[];
        for (let key in bucket.buckets) {
          arr.push({
            doc_count: bucket.buckets[key].doc_count,
            key: key,
            label: bucket.buckets[key].label ?? key,
          });
        }
        bucket.buckets = arr;
      }

      // Create options array
      field.options = bucket.buckets.reduce((arr, bucket) => {
        let label = capitalizeFirstLetter(bucket.label ?? bucket.key);

        // Use translated value if exists, ignoring bad keys
        let i18nBuckets = [] as any[];
        if ((i18nBuckets = bucket?.i18n?.buckets?.filter?.((b) => b.key && b.key !== '-'))?.[0]?.key)
          label = i18nBuckets[0].key;

        arr.push({
          label: label,
          value: bucket.key,
          count: bucket.doc_count,
        });
        return arr;
      }, []);

      // Custom bucket filter function
      if (typeof field.bucketFilter === 'function' && field.options) {
        field.options = field.bucketFilter(field.options);
      }

      field = fn ? fn(field, bucket) : field;
    }

    if (field) {
      newFields.push(field);
    }
  }
  return newFields;
}

export const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const isHLSDelivery = (deliveryType: string) => {
  return ['HLS', 'CMR', 'HÄM', 'HEN', 'HET', 'SAB'].includes(deliveryType);
};
