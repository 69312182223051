import type { ItemPrice } from '@/products.d.ts';

import dayjs from '@/helpers/dayjs';
// import _ from 'lodash'

import { instance, StoredPromises, cancel } from '@/data/api/products/axios';
import { parseJsonStream } from '@/data/api/products/helpers';
import { validateAddressForCustomer } from '@/data/api/kundportal/requests';
import { useAccountStore } from '@/stores/account';
import { useCartStore } from '@/stores/cart';
import { useDebugStore } from '@/stores/debug';
import { usePostalcodeStore } from '@/stores/postalcode';
import { DivisionTypeString } from '@/helpers';
import { FreightResponseV1 } from '../freight/freight';

dayjs.locale('sv');

export function getDefaultLeadtimeOrderType(region = 'EDS') {
  const orderTypes = {
    EDS: 'ORD',
    SAN: '3NO',
    FIN: '4OR',
    KRI: '5NO',
  };
  return orderTypes?.[region] ?? 'ORD';
}

export function getProduct(this: any, id, formId?: string) {
  const _uid = this?._uid ?? '';
  cancel[`products${_uid}`] = new AbortController();
  return instance
    .get(`products/${id}?include_price=${formId}`, {
      signal: cancel[`products${_uid}`].signal,
    })
    .then((r) => r.data?.hits?.hits?.[0]?._source);
}

export function getRelated(this: any, id, formId) {
  const _uid = this?._uid ?? '';
  cancel[`related${_uid}`] = new AbortController();
  return instance
    .get(`products/${id}/related?include_price=${formId}`, {
      signal: cancel[`related${_uid}`].signal,
    })
    .then((r) =>
      r.data.hits.hits.map((hit) => ({
        ...hit._source,
        id: hit._id,
      }))
    );
}

export function getAccessories(this: any, id, formId) {
  const _uid = this?._uid ?? '';
  cancel[`accessories${_uid}`] = new AbortController();
  return instance
    .get(`products/${id}/accessories?size=100&form=${formId}`, {
      signal: cancel[`accessories${_uid}`].signal,
    })
    .then((r) =>
      r.data.hits.hits.map((hit) => ({
        ...hit._source,
        id: hit._id,
      }))
    );
}

export function getDocuments(this: any, documents, locale = 'SWEDISH') {
  if (!documents) return Promise.resolve();
  const _uid = this?._uid ?? '';
  let should = documents.map((id) => ({ match: { _id: id } }));
  const filter = {
    query: {
      bool: {
        should: should,
        minimum_should_match: 1,
        must: {
          match: {
            [`DocumentAvailableMarket.${locale.toUpperCase()}.value`]: 1,
          },
        },
      },
    },
  };

  cancel[`documents${_uid}`] = new AbortController();
  return instance
    .post(
      'documents',
      { filter: filter },
      {
        params: {
          size: documents.length,
        },
        signal: cancel[`documents${_uid}`].signal,
      }
    )
    .then((r) =>
      r.data.hits.hits.map((hit) => ({
        ...hit._source,
        id: hit._id,
      }))
    );
}

export function getArticle(this: any, id, address = '', customerNumber: string | null = null) {
  // if no id, return empty object
  if (!id) return Promise.resolve({});

  const _uid = this?._uid ?? '';
  cancel[`article${_uid}`] = new AbortController();
  return instance
    .get(
      `articles/${id}?${address && customerNumber ? 'include_price=1&' : ''}address=${encodeURIComponent(
        address
      )}&customer=${customerNumber || ''}`,
      {
        signal: cancel[`article${_uid}`].signal,
      }
    )
    .then((r) => (r.data.hits.hits[0] ? r.data.hits.hits[0]._source : null));
}

export function getArticles(this: any, ids: string[]) {
  // if no ids, return empty object
  if (!ids || !ids.length) return Promise.resolve({});

  const _uid = this?._uid ?? '';
  cancel[`articles${_uid}`] = new AbortController();
  return instance
    .get(`articles/${ids.join(',')}?size=${ids.length}`, {
      signal: cancel[`articles${_uid}`].signal,
    })
    .then((r) =>
      r.data.hits.hits.reduce((obj, data) => {
        obj[data._source.id] = data._source;
        return obj;
      }, {})
    );
}

export function getArticlesByArtNr(this: any, artnrs: string[], skip_default_filters = false) {
  // if no artnrs, return empty object
  if (!artnrs || !artnrs.length) return Promise.resolve({});

  const _uid = this?._uid ?? '';

  cancel[`articles${_uid}`] = new AbortController();
  return instance
    .get(`articles/${artnrs.join(',')}`, {
      params: {
        skip_halfpallet_filter: true,
        skip_default_filters: skip_default_filters || '',
        size: artnrs.length,
        is_artnr: true,
      },
      signal: cancel[`articles${_uid}`].signal,
    })
    .then((r) =>
      r.data.hits.hits.reduce((obj, data) => {
        obj[data._source.id] = data._source;
        return obj;
      }, {})
    );
}

export function getSLArticle(artnr: string): Promise<any> {
  return instance.get(`slarticle/${artnr}`).then((r: any) => (r.data ? r.data : null));
}

export function getLastSync() {
  return instance.get('lastsync').then((r: any) => (r.data ? r.data : null));
}

export function searchTexts(
  this: any,
  searchKey?: string,
  textIdentity?: string,
  description?: string,
  page = 0,
  size = 100
) {
  const _uid = this?._uid ?? '';
  cancel[`texts${_uid}`] = new AbortController();
  return instance
    .get('gettexts', {
      params: {
        searchKey: searchKey,
        textIdentity: textIdentity,
        description: description,
        size: size,
        from: page * size,
      },
      signal: cancel[`texts${_uid}`].signal,
    })
    .then((r) => ({
      count: r.data.hits.total.value,
      results: r.data.hits.hits.map((hit) => hit._source),
      aggs: r.data.aggregations,
    }));
}

export function getPrice(
  this: any,
  artnr,
  address = '',
  customerNumber = '',
  { uncancellable = false, warehouse = '', unit = '', noCache = false } = {}
): Promise<{ m2: any; price: ItemPrice[] }> {
  // Log notice about deprecated function
  if (import.meta.env.NODE_ENV === 'development') {
    console.warn('getPrice is deprecated, use getPriceV2 instead');
  }
  const _uid = this?._uid ?? '';
  cancel[`price${_uid}`] = new AbortController();

  return new Promise((resolve, reject) => {
    validateAddressForCustomer(address, customerNumber).then(async (valid) => {
      if (!valid) {
        reject(new Error('Invalid address or customer number'));
      }
      const { region } = useAccountStore();
      resolve(
        instance
          .get(`price/${artnr}`, {
            signal: uncancellable ? null : cancel[`price${_uid}`].signal,
            params: {
              customer: customerNumber,
              address: address,
              warehouse: warehouse,
              unit: unit,
              noCache: noCache,
              region: region,
            },
          })
          .then((r) => r.data)
      );
    });
  });
}

export interface PriceConfig {
  artnr: string;
  address?: string;
  customerNumber?: string;
  warehouse?: string | { id?: string };
  unit?: string;
  region?: DivisionTypeString;

  uncancellable?: boolean;
  noCache?: boolean;
}

export function getPriceV2(this: any, config: PriceConfig): Promise<any> {
  const _uid = this?._uid ?? '';
  cancel[`pricev2${_uid}`] = new AbortController();

  return new Promise((resolve, reject) => {
    validateAddressForCustomer(config?.address, config.customerNumber).then(async (valid) => {
      if (!valid) {
        reject(new Error('Invalid address or customer number'));
      }
      let region = config.region;
      if (!region) {
        region = useAccountStore().region;
      }
      resolve(
        instance
          .get(`v2/price/${config.artnr}`, {
            signal: config.uncancellable ? null : cancel[`pricev2${_uid}`].signal,
            params: {
              customer: config.customerNumber,
              address: config.address,
              warehouse: typeof config.warehouse == 'string' ? config.warehouse : (config.warehouse?.id ?? undefined),
              unit: config.unit,
              noCache: config.noCache,
              region: region,
            },
          })
          .then((r) => r.data)
      );
    });
  });
}

export function getPriceBatch(this: any, batches) {
  const _uid = this?._uid ?? '';
  cancel[`pricebatch${_uid}`] = new AbortController();
  return instance
    .post(
      `batch`,
      batches.map((batch, index) => ({
        id: index,
        method: 'GET',
        path: `v2/price/${batch[0]}?customer=${batch[2]}&address=${encodeURIComponent(batch[1])}`,
      })),
      {
        signal: cancel[`pricebatch${_uid}`].signal,
      }
    )
    .then(parseJsonStream)
    .then((responses) =>
      responses.map((response) => ({
        batch: response.id,
        result: response.status === 200 ? JSON.parse(response.response) : null,
      }))
    );
}

export interface LinePriceConfig {
  artnr: string;
  customerNumber: string;
  quantity: number;
  address?: string;
  priceList?: string;
  priceListOrigin?: string;
  unit?: string;
  warehouse?: string | { id?: string };
  region?: DivisionTypeString;
}

// BEN-1315: 'priceList' & 'priceListOrigin' are not needed anymore, and are now optional.
export function getLinePrice(this: any, config: LinePriceConfig) {
  if (!config.artnr || !config.customerNumber || !config.quantity || config.quantity === Infinity)
    return Promise.resolve({});
  let region = config.region;
  if (!region) {
    region = useAccountStore().region;
  }
  const _uid = this?._uid ?? '';
  cancel[`linePrice${_uid}`] = new AbortController();
  return instance
    .get(`lineprice/${config.artnr}`, {
      signal: cancel[`linePrice${_uid}`].signal,
      params: {
        customer: config.customerNumber,
        address: config.address || '',
        priceList: config.priceList || '',
        priceListOrigin: config.priceListOrigin || '',
        quantity: config.quantity,
        unit: config.unit,
        warehouse: typeof config.warehouse == 'string' ? config.warehouse : (config.warehouse?.id ?? undefined),
        region: region,
      },
    })
    .then((r) => r.data);
}

export function getWarehouseStock(
  this: any,
  { artnr, warehouse, date, unit, batchnr = '', amount = 1, customerNumber = '', orderType = '', artWhitelist = [] }
) {
  // Validate date
  const d = dayjs(date);
  if (!d.isValid() || !unit) return Promise.resolve({}); // Return empty response, hoping an update with correct data will come

  const _uid = this?._uid ?? '';
  const { region, user } = useAccountStore();
  const { info } = useCartStore();
  if (customerNumber === '') {
    if (region == 'SAN') customerNumber = 'K13594';
    else if (region == 'FIN') customerNumber = '700988';
    else if (region == 'KRI') customerNumber = 'T53622';
    else customerNumber = '018309';
  }
  // TODO: orderType should never be 'HLS', ok to remove? (was probably added in ac5334a5)
  customerNumber = orderType === 'HLS' ? '018309' : customerNumber;
  if (!orderType) orderType = getDefaultLeadtimeOrderType(region);
  const customer = user?.customers?.find((c) => c.customerNumber == info?.customer);
  let forceBendersUser = customer?.customerGroup === 'MUC';

  cancel[`warehouse${_uid}`] = new AbortController();
  return instance
    .get(`stock/${artnr}/${warehouse}`, {
      params: {
        date: date,
        amount: amount,
        unit: unit,
        batchnr: batchnr,
        customer: customerNumber,
        ordertype: orderType,
        artwhitelist: JSON.stringify(artWhitelist),
        region: region,
        ...(forceBendersUser
          ? {
              forcebendersuser: forceBendersUser,
            }
          : {}),
      },
      signal: cancel[`warehouse${_uid}`].signal,
    })
    .then((r) => r.data);
}

// export function getWarehouseStockBatch(batches) {
//   const _uid = (this) ? this._uid : "";
//   return instance.post(`batch`, batches.map((batch, index) => ({
//       id: index,
//       method: 'GET',
//       path: `stock/${batch[0]}/${batch[1]}?date=${batch[2]}`,
//     })), {
//     responseType: 'stream',
//     cancelToken: new CancelToken((c) => {if(!Array.isArray(cancel[`warehousebatch${_uid}`])) cancel[`warehousebatch${_uid}`] = []; cancel[`warehousebatch${_uid}`].push(c)})
//   })
//   .then(parseJsonStream)
//   .then(r => r.map(response => ({
//       batch: response.id,
//       result: response.status === 200 ? JSON.parse(response.response) : null,
//   })))
// }

export function getWarehouseLeadtime(
  this: any,
  artnr,
  warehouse,
  date,
  quantity = 1,
  unit = '',
  customerNumber = '',
  orderType = '',
  currentStock = ''
) {
  // Validate date
  const d = dayjs(date);
  if (date && !d.isValid()) return Promise.resolve({});

  const _uid = this?._uid ?? '';
  const { region } = useAccountStore();
  if (customerNumber === '') customerNumber = region == 'SAN' ? 'K13594' : '018309';
  // TODO: orderType should never be 'HLS', ok to remove? (was probably added in ac5334a5)
  customerNumber = orderType === 'HLS' ? '018309' : customerNumber;
  if (!orderType) orderType = getDefaultLeadtimeOrderType(region);
  cancel[`price${_uid}`] = new AbortController();
  return instance
    .get(
      `leadtime/${artnr}/${warehouse}/${quantity}?customer=${
        customerNumber || ''
      }&ordertype=${orderType}&date=${d.format('YYYY-MM-DD')}&unit=${unit}${
        currentStock ? '&currentstock=' + currentStock : ''
      }`,
      {
        params: { region: region },
        signal: cancel[`price${_uid}`].signal,
      }
    )
    .then((r) => r.data);
}

export function getFreight(
  this: any,
  items,
  customerNumber,
  zipCodeTo,
  countryTo,
  warehouseFrom
): Promise<FreightResponseV1> {
  // Validate items
  if (!items.every((item) => item.quantity)) {
    return Promise.reject(new Error('Could not calculate freight, missing quantity'));
  }

  const _uid = this?._uid ?? '';
  cancel[`freight${_uid}`] = new AbortController();
  return instance
    .post(
      `getfreight`,
      {
        items: items,
        customerNumber: customerNumber,
        zipCodeTo: zipCodeTo,
        countryTo: countryTo,
        warehouseFrom: warehouseFrom,
      },
      {
        signal: cancel[`freight${_uid}`].signal,
      }
    )
    .then((r) => r.data);
}

export function search(this: any, payload) {
  const _uid = this?._uid ?? '';
  if (!Array.isArray(cancel[`search${_uid}`])) cancel[`search${_uid}`] = [];
  const abortController = new AbortController();
  cancel[`search${_uid}`].push(abortController);

  const { debugMode } = useDebugStore();

  if (debugMode) {
    payload.explain = true;
  }

  return instance
    .post(`search`, payload, {
      signal: abortController.signal,
      params: {
        debug: debugMode, // Adds explanation to the response
      },
    })
    .then((r) => r.data);
}

export function searchRestStock(this: any, payload) {
  const _uid = this?._uid ?? '';
  if (!Array.isArray(cancel[`searchrest${_uid}`])) cancel[`searchrest${_uid}`] = [];
  cancel[`searchrest${_uid}`].push(new AbortController());
  return instance
    .post(`search-reststock`, payload, {
      signal: cancel[`searchrest${_uid}`].signal,
    })
    .then((r) => r.data);
}

export function searchWeber(this: any, payload) {
  const _uid = this?._uid ?? '';

  cancel[`weber${_uid}`] = new AbortController();
  return instance
    .post(`search-weber`, payload, {
      signal: cancel[`weber${_uid}`].signal,
    })
    .then((r) => r.data);
}

export function getBatchArticles(this: any, artnr, warehouse, params) {
  const _uid = this?._uid ?? '';
  cancel[`batcharticles${_uid}`] = new AbortController();
  return instance
    .get(`batcharticles/${artnr}/${warehouse}`, {
      params: params,
      signal: cancel[`batcharticles${_uid}`].signal,
    })
    .then((r) => r.data);
}

export function warehouseByLocation(this: any, artnr) {
  const { postalcode, warehouseLocation, setWarehouseLocation } = usePostalcodeStore();
  let pcode = postalcode;
  if (!pcode) pcode = useCartStore().info.defaultPostalcode.replace(/\D/g, '');
  const _uid = this?._uid ?? '';
  if (warehouseLocation[artnr]) {
    return Promise.resolve(warehouseLocation[artnr]);
  }
  cancel[`warehousebylocation${_uid}`] = new AbortController();
  StoredPromises[`${artnr}_${postalcode}`] =
    StoredPromises[`${artnr}_${postalcode}`] ||
    new Promise((resolve) => {
      instance
        .get(`warehousebylocation/${artnr}/${postalcode}`, {
          signal: cancel[`warehousebylocation${_uid}`].signal,
        })
        .then((r) => {
          StoredPromises[`${artnr}_${postalcode}`] = null;
          if (r.data)
            setWarehouseLocation({
              artnr,
              warehouse: r.data,
            });
          resolve(r.data);
          return r.data;
        })
        .catch(() => {
          resolve(null);
          return null;
        });
    });
  return StoredPromises[`${artnr}_${postalcode}`];
}

export function cancelRequest(this: any, type = '', reason = 'canceled') {
  const _uid = this?._uid ?? '';
  let controller = cancel[`${type}${_uid}`] || cancel[type];
  if (Array.isArray(controller)) {
    for (let i = 0; i < controller.length; i++) controller[i].abort(reason);
  } else if (controller) {
    controller.abort(reason);
  }
}
